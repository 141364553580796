const regionInputContainer = '.region-input-container'
const regionInputSelector = '.region-select'
const usBundlesSelector = '.us-bundle-option'
const caBundlesSelector = '.ca-bundle-option'
const hiddenCss = 'display-none'
const colCss = 'col-md-6'

export default () => {
  window.addEventListener('proposal_region:change', () => {
    updateVisibleBundles()
  })
  updateVisibleBundles()
}

const getRegionValue = () => {
  return document.querySelector(regionInputSelector).value
}

const updateVisibleBundles = () => {
  const value = getRegionValue()
  if (value === "us") {
    document.querySelector(usBundlesSelector).classList.remove(hiddenCss)
    document.querySelector(usBundlesSelector).classList.add(colCss)
    document.querySelector(caBundlesSelector).classList.add(hiddenCss)
    document.querySelector(caBundlesSelector).classList.remove(colCss)
  }

  if (value === "ca") {
    document.querySelector(caBundlesSelector).classList.remove(hiddenCss)
    document.querySelector(caBundlesSelector).classList.add(colCss)
    document.querySelector(usBundlesSelector).classList.add(hiddenCss)
    document.querySelector(usBundlesSelector).classList.remove(colCss)
  }
}

