export default () => {
	const ellipsisToggles = document.querySelectorAll('.toggle-ellipsis')

	if (ellipsisToggles) {
		for (let i = 0; i < ellipsisToggles.length; i += 1) {
			const toggleLink = ellipsisToggles[i]
			const strong = toggleLink.parentElement.children[0]
			
			if (strong.scrollHeight <= 50) {
				toggleLink.parentElement.removeChild(toggleLink)
				continue
			}
			
			toggleLink.addEventListener('click', (e) => {
				e.preventDefault()
				
				if (strong.className === 'ellipsis') {
					strong.className = ''
					toggleLink.innerHTML = 'Show less'
				} else {
					strong.className = 'ellipsis'
					toggleLink.innerHTML = 'Show more'
				}
			})
		}
	}
}