export default () => {
	const getParams = (form) => {
		const formData = Object.fromEntries(new FormData(form))
		delete formData.authenticity_token
		delete formData["proposal[quotes][]"]

		formData["proposal[quotes]"] = Object.assign([], document.querySelectorAll('[name="proposal[quotes][]"]:checked')).map(e => e.value).join(",")

		return new URLSearchParams(formData).toString()
	}
		
	const init = () => {
		const proposalLink = document.getElementById('preview-proposal')
		const emailLink = document.getElementById('preview-email')
		const form = document.getElementById('proposal-form')

		if (proposalLink && emailLink && form) {
			proposalLink.addEventListener('click', (e) => {
				if (e.target.getAttribute('href') === null) {
					e.preventDefault()
					return
				}

				const params = getParams(form)
				const href = e.target.getAttribute('href') + "?type=html&" + params
				document.getElementById('preview-iframe').setAttribute('src', href)
			})

			emailLink.addEventListener('click', (e) => {
				if (e.target.getAttribute('href') === null) {
					e.preventDefault()
					return
				}

				const params = getParams(form)
				const href = e.target.getAttribute('href') + "?type=email&" + params
				document.getElementById('preview-iframe').setAttribute('src', href)
			})

			document.body.addEventListener('ajax:error', () => {
				window.setTimeout(() => {
					init()
				}, 200)
			})
		}
	}

	init()
}