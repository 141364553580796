export default () => {
  const usBundleInput = document.getElementById('proposal_us_bundle_option')
  const caBundleInput = document.getElementById('proposal_ca_bundle_option')
	const approvedDiscountInput = document.getElementById('proposal_approved_discount')
	const bundleOfferedAtInput = document.getElementById('bundle_solution_offered_at')
	const afterDiscountInput = document.getElementById('proposal_bundle_solution_after_discount')
	const suggestions = document.querySelectorAll('[data-action="suggest"]')

	if (!usBundleInput || !caBundleInput || !approvedDiscountInput || !bundleOfferedAtInput || !afterDiscountInput) {
    console.debug('proposal form not found, skipping setup')
    return
  }

	let size = ""
	let discount = (parseInt(approvedDiscountInput.value) / 100) || 0

	const init = () => {
    window.initSolutions = true;
    console.debug('init solutions.js')
		window.addEventListener('proposal_us_bundle_option:change', (e) => {
      console.debug('proposal_us_bundle_option:change')
			size = usBundleInput.value
			calculate()
		})

    window.addEventListener('proposal_ca_bundle_option:change', (e) => {
      console.debug('proposal_ca_bundle_option:change')
      size = caBundleInput.value
      calculate()
    })

		approvedDiscountInput.addEventListener('change', (e) => {
			discount = e.target.value / 100
			calculate()
		})

		approvedDiscountInput.addEventListener('keyup', (e) => {
			discount = e.target.value / 100
			calculate()
		})
	}

	const calculate = () => {
    console.debug(calculate)
		let solutionPrice

    console.debug(size)

		if (size === "") return

    let sol = window.bundle_options_data[size][0]
    console.debug(sol)

    solutionPrice = sol.price_in_cents / 100

    let formatter

    console.debug(sol)

    if (sol.region === "us") {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    }

    if (sol.region == "ca") {
      formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD'
      })
    }

		bundleOfferedAtInput.setAttribute('value', formatter.format(solutionPrice))
		afterDiscountInput.setAttribute('value', formatter.format(solutionPrice - (solutionPrice * discount)))
	}
	
	init()

	const initSuggestions = () => {
		suggestions.forEach(suggestion => {
			suggestion.addEventListener('click', (e) => {
				e.preventDefault()
				const value = suggestion.getAttribute('data-value')
        console.debug(value)
				approvedDiscountInput.value = value
				approvedDiscountInput.dispatchEvent(new Event('change'))
			})
		})
	}

	if (suggestions) {
		initSuggestions()
	}
}
