// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "popper.js"
import $ from "jquery"
import "bootstrap"
import 'select2'
import Splide from "@splidejs/splide"

import "../src/scss/style.scss"

import initEllipsis from "../src/js/ellipsis"
import initProfile from "../src/js/profile"
import initSolutions from "../src/js/solutions"
import initPreviewProposal from '../src/js/prevew-proposal'
import initRegionBundleToggle from '../src/js/region-bundle-toggle'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.$ = $

document.addEventListener("turbolinks:load", function() {
	$('select').select2({
		dropdownCssClass: "",
		minimumResultsForSearch: -1
	}).on('select2:select', (e) => {
		if (e.target.getAttribute('data-submit') === "true") {
			e.target.form.submit()
		}

		const event = new CustomEvent(`${e.target.id}:change`, { detail: e.target.value })
		window.dispatchEvent(event)
		e.target.dispatchEvent(event)
	})

	window.addEventListener('ajax:error', (e) => {
		const form = document.getElementById('form')
		form.innerHTML = e.detail[2].response
	})

	if (document.querySelector('.splide')) {
		new Splide('.splide', {
			type: 'loop'
		}).mount()
	}
})

document.addEventListener('turbolinks:visit', () => {
	$('select').select2('destroy')
})

document.addEventListener('turbolinks:load', () => {
  console.debug('turbolinks:load')
	$('.modal[data-autoplay="true"]').modal('show')
	initProfile()
	initEllipsis()	
	initSolutions()
	initPreviewProposal()
  initRegionBundleToggle()

	document.querySelectorAll('form').forEach((el) => {
		el.addEventListener('keydown', (e) => {
			if(e.key == "Enter") {
				e.preventDefault();
				return false;
			}
		})
	})
})

