export default () => {
	const myProfileButton = document.querySelector('.toggle-my-profile')

	if (myProfileButton) {
		myProfileButton.addEventListener('click', (e) => {
			e.preventDefault()

			const profileDropdown = document.querySelector('.profile-dropdown')

			if (profileDropdown) {
				if (profileDropdown.className.indexOf(' d-none') > -1) {
					profileDropdown.className = profileDropdown.className.replace(' d-none', '')
				} else {
					profileDropdown.className = profileDropdown.className + ' d-none'
				}
			}
		})
	}
}